import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_uipcv7oAosTqHCLrDDbtENc9Xwt1PnnChKPXu5AVcjE from "/vercel/path0/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_1iRQgYFRR7xfuMumrkN4UGvWFXdQAoiR_NXI9qrJALY from "/vercel/path0/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_yjz_FxLIxadqwp6_Sovn2pVgsO0iA7tScVL_bgYsjTE from "/vercel/path0/node_modules/vue-sonner/lib/nuxt/runtime/plugin.js";
import siteConfig_vuqmRkLAUZxQvb5pvUwT3uUdVggfjhj1m5v7Pb6IE0w from "/vercel/path0/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_KsEotgC9NJyW_guR_3z04hFN8TI2h5dgP8bzHmpMm5o from "/vercel/path0/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_Fth_MAhm7dgpxeTaMXibYXbcCjegjWK3QH9gKvbTRVg from "/vercel/path0/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import _0_app_IxBqygt61aZVh1Uf99QBM81qEf9KMYyFECjEm8bS1H0 from "/vercel/path0/plugins/0.app.js";
import auth_aAlH4NH7VxtGYJ4TVESCfzF_dcOUAJCrrZvgU4k2EHs from "/vercel/path0/plugins/auth.js";
import axios_noAkUduv0vf78s7vht2Hls1EDqDfAXigM3_vcuRZv2s from "/vercel/path0/plugins/axios.js";
import cloud_MXaF8FbJDk9DOyoHp2VPaX7YaQkhCIZOedU4zLc4T5Y from "/vercel/path0/plugins/cloud.js";
import command_palette_client_6kH_BAu22eaeQ2cxU17PvvE2VnZJzDqcn7TCuvKmzS8 from "/vercel/path0/plugins/command-palette.client.ts";
import dayjs_kv5GwBP1CWWSncr8C3P8wyxEDm654kfGaK3S1a89UyA from "/vercel/path0/plugins/dayjs.js";
import dexie_client_wn9gJTnbMmzN_SQoHBxj1Yo_bRLyBI5CJZO3tp3aClw from "/vercel/path0/plugins/dexie.client.js";
import events_client_Orkreot8_rk_7kKf_V8cuTJTvjbY0mw4kha8XiIiJ_Q from "/vercel/path0/plugins/events.client.js";
import importer_client_MgpX5fPiyi5A5U_j54WIJxC0wvE9mZNQuj_lAeVmRtc from "/vercel/path0/plugins/importer.client.js";
import mitt_client_3RH44hEfavok6dHN_g_gpIbvib6DMLYKKJbsBqo29n8 from "/vercel/path0/plugins/mitt.client.js";
import modal_client_EVWbbV18as3KYPs78Te0TFDq8VfvyYKigPkV31xBmB0 from "/vercel/path0/plugins/modal.client.ts";
import photoswipe_client_nD63_dOV_qt9tSKcN8_B__JMwKhVAYzwDLlqRg_5ZGk from "/vercel/path0/plugins/photoswipe.client.js";
import swiper_client_JZJ7Ca7NbawPpkhdB99T08ptHmHQII_TP5_9OXFLdLo from "/vercel/path0/plugins/swiper.client.js";
import sync_client_WkOTvpbfcjmK5mjoR62TVyxLK5AYBI798Dwv94ndi3g from "/vercel/path0/plugins/sync.client.js";
import tippy_dgRcAYWb4gyKuxn5JvTgp5sN35SM_BQ_KBI302vzY2Q from "/vercel/path0/plugins/tippy.js";
import uuid_EKRwr_ebjdiPTI8BlCZeCciYP2fWrRMU6G2AQhx8wso from "/vercel/path0/plugins/uuid.js";
import wowfactor_client_T_Z29Y3Sdp5khvld77lHjt0FjRbh_OlHNHKGfd8GWRQ from "/vercel/path0/plugins/wowfactor.client.js";
import zapp_client_dqmbRCRuFOLJ2DoqMon8HEATZX906REln0IjGGlb9Mw from "/vercel/path0/plugins/zapp.client.js";
import vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk from "/vercel/path0/.nuxt/vuetify-nuxt-plugin.client.mjs";
import defaults_0Sn7xIMAzGkdbab2otVWD8mX4GpY74A3Jy_gY_4_qYk from "/vercel/path0/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  vuetify_icons_uipcv7oAosTqHCLrDDbtENc9Xwt1PnnChKPXu5AVcjE,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  vuetify_no_client_hints_1iRQgYFRR7xfuMumrkN4UGvWFXdQAoiR_NXI9qrJALY,
  plugin_yjz_FxLIxadqwp6_Sovn2pVgsO0iA7tScVL_bgYsjTE,
  siteConfig_vuqmRkLAUZxQvb5pvUwT3uUdVggfjhj1m5v7Pb6IE0w,
  inferSeoMetaPlugin_KsEotgC9NJyW_guR_3z04hFN8TI2h5dgP8bzHmpMm5o,
  titles_Fth_MAhm7dgpxeTaMXibYXbcCjegjWK3QH9gKvbTRVg,
  _0_app_IxBqygt61aZVh1Uf99QBM81qEf9KMYyFECjEm8bS1H0,
  auth_aAlH4NH7VxtGYJ4TVESCfzF_dcOUAJCrrZvgU4k2EHs,
  axios_noAkUduv0vf78s7vht2Hls1EDqDfAXigM3_vcuRZv2s,
  cloud_MXaF8FbJDk9DOyoHp2VPaX7YaQkhCIZOedU4zLc4T5Y,
  command_palette_client_6kH_BAu22eaeQ2cxU17PvvE2VnZJzDqcn7TCuvKmzS8,
  dayjs_kv5GwBP1CWWSncr8C3P8wyxEDm654kfGaK3S1a89UyA,
  dexie_client_wn9gJTnbMmzN_SQoHBxj1Yo_bRLyBI5CJZO3tp3aClw,
  events_client_Orkreot8_rk_7kKf_V8cuTJTvjbY0mw4kha8XiIiJ_Q,
  importer_client_MgpX5fPiyi5A5U_j54WIJxC0wvE9mZNQuj_lAeVmRtc,
  mitt_client_3RH44hEfavok6dHN_g_gpIbvib6DMLYKKJbsBqo29n8,
  modal_client_EVWbbV18as3KYPs78Te0TFDq8VfvyYKigPkV31xBmB0,
  photoswipe_client_nD63_dOV_qt9tSKcN8_B__JMwKhVAYzwDLlqRg_5ZGk,
  swiper_client_JZJ7Ca7NbawPpkhdB99T08ptHmHQII_TP5_9OXFLdLo,
  sync_client_WkOTvpbfcjmK5mjoR62TVyxLK5AYBI798Dwv94ndi3g,
  tippy_dgRcAYWb4gyKuxn5JvTgp5sN35SM_BQ_KBI302vzY2Q,
  uuid_EKRwr_ebjdiPTI8BlCZeCciYP2fWrRMU6G2AQhx8wso,
  wowfactor_client_T_Z29Y3Sdp5khvld77lHjt0FjRbh_OlHNHKGfd8GWRQ,
  zapp_client_dqmbRCRuFOLJ2DoqMon8HEATZX906REln0IjGGlb9Mw,
  vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk,
  defaults_0Sn7xIMAzGkdbab2otVWD8mX4GpY74A3Jy_gY_4_qYk
]