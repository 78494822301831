
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as auth7OMn_45RoyumEofPwA4BlS_45YlbPnH7bLXKV0RJxD4jYUkMeta } from "/vercel/path0/pages/auth.vue?macro=true";
import { default as testqjsskmYe4C6ARrH52MZQwc40dJZ_XoBicua2duVTK68Meta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as sitemapnpadVI10GvN_45Y_45o1so1bwWJcyYretVyWs_xkYJE7aFUMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
import { default as welcomeKzeXs0p_eM_45zf48NWG2WREJVkFVHHBpdRn4wjKOgR7IMeta } from "/vercel/path0/pages/welcome.vue?macro=true";
import { default as supabaseMtgyCy7NrjZuhsKQltjBZIV0NtLE1eS5eGnQl9SbuekMeta } from "/vercel/path0/pages/supabase.vue?macro=true";
import { default as theloginaJt6WqIQy2HQmlUPr_dyuQwEkmQmYbQC3xUILKk_z60Meta } from "/vercel/path0/pages/thelogin.vue?macro=true";
import { default as changelogmXDXpd6JLfhdg_45LhgNpsFqptgHiN1Yyutij0fBnGKDwMeta } from "/vercel/path0/pages/changelog.vue?macro=true";
import { default as dashboardKgoay9VAK7CxGVzaZM9Nk6S4QXOmNGok_45ZJZY_Gdk3QMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as _91slug_93lobVjMao7dzL9UMjA5rp86Ldg09kxZjgaU_45bSYFw4AsMeta } from "/vercel/path0/pages/game/[slug].vue?macro=true";
import { default as indexjsKDwhABWlcREwIY1UD1uYNLoKjrpMF85J_45EmhUSgmEMeta } from "/vercel/path0/pages/genres/index.vue?macro=true";
import { default as indexuC6khTqSGNlYkVfDbIKSCxdebDH5961OCeQVyp27ehoMeta } from "/vercel/path0/pages/import/index.vue?macro=true";
import { default as indexzstSAbUVOxFyrG4epW9LiK9NjJX7tD8cF30wOEyhWF4Meta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as changelog_45wip_45IwCLaqvM7QKBFVaCRHnj5qwz1o3Rs37nIhyvkP9jSoMeta } from "/vercel/path0/pages/changelog-wip.vue?macro=true";
import { default as indexo9o9ks9lrg3jO5Av0lH2MOsTAEvlITVWqFGvYIFl2FkMeta } from "/vercel/path0/pages/journal/index.vue?macro=true";
import { default as update0FEWCb9VbZu24NaRnxPDMkHF0XnWriVoS8AMo7OiwWsMeta } from "/vercel/path0/pages/account/update.vue?macro=true";
import { default as _91_46_46_46slug_93XUDk66w47cWyM97SqM2gY1JOsjoQylIs7LePT_45CLZ9YMeta } from "/vercel/path0/pages/docs/[...slug].vue?macro=true";
import { default as indexKWn0df6M3tLWnarV2E4hRT1kzJEU_knkWPdT04_45g9zQMeta } from "/vercel/path0/pages/community/index.vue?macro=true";
import { default as _91_46_46_46slug_93O812nhhZerftEhQsVrugp6S9k0VX0SvJbeETpCo4fYUMeta } from "/vercel/path0/pages/games/[...slug].vue?macro=true";
import { default as _91section_93FI8cXAbJe6XeJ0cB1kKYehaI2THhayov0n_BWe9uDXMMeta } from "/vercel/path0/pages/account/[section].vue?macro=true";
import { default as _91platform_938BpQjHnSHC2OCDCLB_U4IpnAwxvWiLkSiSUkywzbOz4Meta } from "/vercel/path0/pages/import/[platform].vue?macro=true";
import { default as _91_46_46_46slug_93dBzykd4x_45MlDJDoO64jHkJVe87QHwbZt0NtfM3QuzdoMeta } from "/vercel/path0/pages/library/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93Ud4moQfNKJjhzT3EYMpfBnHkfeuWKO9FwnUeHRWqspgMeta } from "/vercel/path0/pages/my/list/[...slug].vue?macro=true";
import { default as _91slug_93Gxut_JT8d4jZwiWiJ2tm1_45cFQe_XbQKkAHCH6bI_45rKkMeta } from "/vercel/path0/pages/@[user]/list/[slug].vue?macro=true";
import { default as mebGJuGoZcM3SND6kBR7Lbo20PvL3dH5ugKfwfxONRCTQMeta } from "/vercel/path0/pages/account/sections/me.vue?macro=true";
import { default as logsryLm_bkR_45tAvpE7x2Heu3U4JMmBl7B2_vUk3cZzBiGsMeta } from "/vercel/path0/pages/account/sections/logs.vue?macro=true";
import { default as cloudp1Uy9uMy5rqEZ9qjV8iWxae29GVxPb5DD9xlmGCVRQkMeta } from "/vercel/path0/pages/account/sections/cloud.vue?macro=true";
import { default as listsUx5Sh3reAF4c3sIkT_45bS_GH8aIBsPQ_45Td0Hyh_45ZvXCIMeta } from "/vercel/path0/pages/account/sections/lists.vue?macro=true";
import { default as linked3iVHluYfD7_45uxsJo_45sv3NfGimG2JxzmDiWPim5dDsn8Meta } from "/vercel/path0/pages/account/sections/linked.vue?macro=true";
import { default as statesGzhNZ1kGutsRXNvVKnKY4yMGfgAlyU_YVaf6moXFJdoMeta } from "/vercel/path0/pages/account/sections/states.vue?macro=true";
import { default as historyqZmN0BGq29sDwEDlJwRpGUaHdj39OBhMK8Gcn7w81pMMeta } from "/vercel/path0/pages/account/sections/history.vue?macro=true";
import { default as databaseq60d3Uw3R7MvTuCoQzv8TBc4E5AqyaibYopcqnhfcbgMeta } from "/vercel/path0/pages/account/sections/database.vue?macro=true";
import { default as community_45iwXB26f3fDY_jW5NL7Am_1dlLByhk0BlVGUCxaSvzAMeta } from "/vercel/path0/pages/account/sections/community.vue?macro=true";
import { default as preferencesQD_AnIYfp2JJgBntmz8aRfEAg9CLP_45n_45qnMJID3V_UkMeta } from "/vercel/path0/pages/account/sections/preferences.vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    component: () => import("/vercel/path0/pages/auth.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/pages/test.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomeKzeXs0p_eM_45zf48NWG2WREJVkFVHHBpdRn4wjKOgR7IMeta || {},
    component: () => import("/vercel/path0/pages/welcome.vue")
  },
  {
    name: "supabase",
    path: "/supabase",
    component: () => import("/vercel/path0/pages/supabase.vue")
  },
  {
    name: "thelogin",
    path: "/thelogin",
    component: () => import("/vercel/path0/pages/thelogin.vue")
  },
  {
    name: "changelog",
    path: "/changelog",
    component: () => import("/vercel/path0/pages/changelog.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/vercel/path0/pages/dashboard.vue")
  },
  {
    name: "game-slug",
    path: "/game/:slug()",
    component: () => import("/vercel/path0/pages/game/[slug].vue")
  },
  {
    name: "genres",
    path: "/genres",
    component: () => import("/vercel/path0/pages/genres/index.vue")
  },
  {
    name: "import",
    path: "/import",
    meta: indexuC6khTqSGNlYkVfDbIKSCxdebDH5961OCeQVyp27ehoMeta || {},
    component: () => import("/vercel/path0/pages/import/index.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexzstSAbUVOxFyrG4epW9LiK9NjJX7tD8cF30wOEyhWF4Meta || {},
    redirect: "/account/me",
    component: () => import("/vercel/path0/pages/account/index.vue")
  },
  {
    name: "changelog-wip",
    path: "/changelog-wip",
    component: () => import("/vercel/path0/pages/changelog-wip.vue")
  },
  {
    name: "journal",
    path: "/journal",
    component: () => import("/vercel/path0/pages/journal/index.vue")
  },
  {
    name: "account-update",
    path: "/account/update",
    meta: update0FEWCb9VbZu24NaRnxPDMkHF0XnWriVoS8AMo7OiwWsMeta || {},
    component: () => import("/vercel/path0/pages/account/update.vue")
  },
  {
    name: "docs-slug",
    path: "/docs/:slug(.*)*",
    component: () => import("/vercel/path0/pages/docs/[...slug].vue")
  },
  {
    name: "community",
    path: "/community",
    component: () => import("/vercel/path0/pages/community/index.vue")
  },
  {
    name: "games-slug",
    path: "/games/:slug(.*)*",
    component: () => import("/vercel/path0/pages/games/[...slug].vue")
  },
  {
    name: "account-section",
    path: "/account/:section()",
    component: () => import("/vercel/path0/pages/account/[section].vue")
  },
  {
    name: "import-platform",
    path: "/import/:platform()",
    meta: _91platform_938BpQjHnSHC2OCDCLB_U4IpnAwxvWiLkSiSUkywzbOz4Meta || {},
    component: () => import("/vercel/path0/pages/import/[platform].vue")
  },
  {
    name: "library-slug",
    path: "/library/:slug(.*)*",
    component: () => import("/vercel/path0/pages/library/[...slug].vue")
  },
  {
    name: "my-list-slug",
    path: "/my/list/:slug(.*)*",
    component: () => import("/vercel/path0/pages/my/list/[...slug].vue")
  },
  {
    name: "@user-list-slug",
    path: "/@:user()/list/:slug()",
    component: () => import("/vercel/path0/pages/@[user]/list/[slug].vue")
  },
  {
    name: "account-sections-me",
    path: "/account/sections/me",
    component: () => import("/vercel/path0/pages/account/sections/me.vue")
  },
  {
    name: "account-sections-logs",
    path: "/account/sections/logs",
    component: () => import("/vercel/path0/pages/account/sections/logs.vue")
  },
  {
    name: "account-sections-cloud",
    path: "/account/sections/cloud",
    component: () => import("/vercel/path0/pages/account/sections/cloud.vue")
  },
  {
    name: "account-sections-lists",
    path: "/account/sections/lists",
    component: () => import("/vercel/path0/pages/account/sections/lists.vue")
  },
  {
    name: "account-sections-linked",
    path: "/account/sections/linked",
    component: () => import("/vercel/path0/pages/account/sections/linked.vue")
  },
  {
    name: "account-sections-states",
    path: "/account/sections/states",
    component: () => import("/vercel/path0/pages/account/sections/states.vue")
  },
  {
    name: "account-sections-history",
    path: "/account/sections/history",
    component: () => import("/vercel/path0/pages/account/sections/history.vue")
  },
  {
    name: "account-sections-database",
    path: "/account/sections/database",
    component: () => import("/vercel/path0/pages/account/sections/database.vue")
  },
  {
    name: "account-sections-community",
    path: "/account/sections/community",
    component: () => import("/vercel/path0/pages/account/sections/community.vue")
  },
  {
    name: "account-sections-preferences",
    path: "/account/sections/preferences",
    component: () => import("/vercel/path0/pages/account/sections/preferences.vue")
  }
]